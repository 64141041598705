export const patientGTI: any = {
  mainMenu: "Main Navigational Menu Consumer Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu Consumer Retina Brazil (PT)",
  banner: "Banner - HTML - GTB - DTC - Brazil (PT)",
  footer: "Footer - Consumer - Retina Brazil (PT)",
  exitPopup: "Exit Popup Brazil (PT)",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu Retina Brazil (PT)",
  footerRef: "DTC - Genetic Testing Benefits Page - Reference - Brazil (PT)",
  infographic: "GENETIC TESTING BENIFITS- INFOGRAPHIC - Retina Brazil (PT)",
  footerCallout: "Footer Callout GTB - HTML DTC Retina Brazil (PT)",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  moreAnswersSection: "More answers HTML - GTB Consumer Retina Brazil (PT)",
  over270Genes: "Over 270 Genes HTML - GTB Consumer Retina Brazil (PT)",
  earlyTesting: "Early genetic testing HTML - GTB - Consumer Retina Brazil (PT)",
  prepareToSpeak: "Prepare to speak with your eye specialist - Retina Brazil (PT)",
  GTInconclusive: "Genetic Testing Inconclusive - Brazil (PT)",
  backToTopGTM: "back to top - genetic testing benefits",
  footerClassName: "genetic-testing-importance-footer"
}
